import store from '@/store/index.js'
import storage from '@/utils/storage.js'
import useService from '@/services/index.js'
import { login, logout } from '@/services/app.js'

const user = {
    namespaced: true,
	
    state: {
        token: storage.session.get('token'),
		userId: storage.session.get('user_id'),
		isSuperAdmin: storage.session.get('isSuperAdmin'),
		userData: storage.session.getObject('user_data'),
        displayName: storage.session.get('displayName'),
        avatarUrl: storage.session.get('avatarUrl'),
        roles: storage.session.getObject('roles', []),
		permissions: storage.session.getObject('permissions', ['*'])
    },
    
    mutations: {
        SET_TOKEN(state, data) {
            state.token = data;
            storage.session.set('token', data);
        },
		
		SET_REMEMBER(state, data) {
			const { username, passwd, remember } = data
			state.userData = { username, passwd, remember}
			storage.session.setObject('user_data', state.userData)
		},
        
        SET_USER_INFO(state, data) {
			const { aid, displayName, avatarUrl, is_super_admin, roles, permissions } = data || {}
			if(aid) {
				state.userId = aid;
				storage.session.set('user_id', aid);
			} 
			
			state.isSuperAdmin = !!is_super_admin
			storage.session.set('isSuperAdmin', !!is_super_admin)
		
			if(displayName) {
				state.displayName = displayName
				storage.session.set('displayName', displayName)
			}
			if(avatarUrl) {
				state.avatarUrl = avatarUrl
				storage.session.set('avatarUrl', avatarUrl)
			}
			if(roles) {
				state.roles = roles
				storage.session.setObject('roles', roles)
			}
			console.log(permissions)
			if(permissions) {
				state.permissions = permissions
				storage.session.setObject('permissions', permissions)
			}
        },
		
		REMOVE_TOKEN(state) {
			state.token = '';
			state.userId = '';
			state.menuList = '';
			storage.session.clear();
		}
    },
    
    actions: {
        async Login({ commit }, data) {
			// 记住账号密码
			if(data.remember) {
				commit('SET_REMEMBER', data)
			}
			
			const loginRes = await login(data);
			// 登录成功
			if(loginRes.code === 200) {
				const {token, aid, nickname, avatar_url, mobile, is_super_admin, email, roles, permissions } = loginRes.data
				
				commit('SET_TOKEN', token)
				commit('SET_USER_INFO', {
					aid,
					is_super_admin,
					displayName: nickname,
					avatarUrl: avatar_url,
					mobile,
					email,
					roles,
					permissions
				})
				
				// 刷新菜单
				await store.dispatch('app/refreshMenu')
			}
			
			return loginRes;
        },
		
		Refresh({ commit }) {
			return useService('admin').post('getInfo').then(res => {
				if(res.code === 200) {
					const {id, nickname, avatar_url, mobile, email, roles } = res.data
					commit('SET_USER_INFO', {
						aid: id,
						displayName: nickname,
						avatarUrl: avatar_url,
						mobile,
						email,
						roles
					})
				}
			})
		},

        Logout({ commit }) {
			return logout().then(res => {
				if(res.code === 200) {
					return commit('REMOVE_TOKEN')
				}
			})
        }
    }
}

export default user